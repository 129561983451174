/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { Path, UseFormRegister, FieldError } from 'react-hook-form';

import IFormValues from '@heureca/shared/models/IFormValues.model';

import * as styles from './input.module.scss';

interface Props {
  label: string;
  name: Path<IFormValues>;
  placeholder?: string
  wrapperClassName?: string;
  register?: UseFormRegister<IFormValues>;
  required?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: FieldError;
  validate?: (value: string) => boolean;
  customErrorMessage?: string;
  mask?: string;
}

const Input = (props: Props) => {
  const {
    label,
    name,
    placeholder,
    wrapperClassName,
    register,
    required,
    value,
    onChange,
    error,
    validate,
    customErrorMessage,
    mask,
  } = props;

  const [internalValue, setInternalValue] = useState(value);
  const registerProps = register(name, { required, validate, value: internalValue });

  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(event.currentTarget.value);
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value || '');
    }
  }, [value]);

  return (
    <div className={classNames(styles.inputWrapper, wrapperClassName)}>
      <label htmlFor={label} className={styles.label}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
      {mask ? (
        <InputMask
          {...registerProps}
          type="text"
          onChange={onChanged}
          value={internalValue}
          className={classNames(styles.input, { [styles.error]: error })}
          name={name}
          placeholder={placeholder}
          mask={mask}
        />
      ) : (
        <input
          {...registerProps}
          type="text"
          onChange={onChanged}
          value={internalValue}
          className={classNames(styles.input, { [styles.error]: error })}
          name={name}
          placeholder={placeholder}
        />
      )}
      <p className={classNames({ [styles.errorMessage]: !error })}>
        {customErrorMessage || "Le champ n'est pas valide"}
      </p>
    </div>
  );
};

export default Input;
